// extracted by mini-css-extract-plugin
export var tabContent = "_infoinvoice-module--tabContent--tSOCS";
export var tabLinks = "_infoinvoice-module--tabLinks--K6aVQ";
export var tab = "_infoinvoice-module--tab--oYJ0g";
export var contentWrapper = "_infoinvoice-module--contentWrapper--9e40I";
export var checkboxShow = "_infoinvoice-module--checkboxShow--yQIf8";
export var checkbox = "_infoinvoice-module--checkbox--jsGhn";
export var checkboxTable = "_infoinvoice-module--checkboxTable--0oUmY";
export var checkboxTableRowInfo = "_infoinvoice-module--checkboxTableRowInfo--iunSM";
export var checkboxTableRowSet = "_infoinvoice-module--checkboxTableRowSet--eF+gY";
export var taxBr = "_infoinvoice-module--taxBr--UKCj7";
export var functionTable = "_infoinvoice-module--functionTable--t-jOh";
export var imgXS = "_infoinvoice-module--imgXS--FRzmx";
export var imgS = "_infoinvoice-module--imgS--9RVzx";
export var imgM = "_infoinvoice-module--imgM--gJbDL";
export var layoutTbl = "_infoinvoice-module--layoutTbl--FhP3m";
export var stepsWeb = "_infoinvoice-module--stepsWeb--q4MkK";
export var stepsMobile = "_infoinvoice-module--stepsMobile--cB-C-";