import * as React from "react"
import { useEffect, useState } from 'react'

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import { SRLWrapper } from "simple-react-lightbox";
import { useLocation } from "@reach/router"

import * as styles from "src/scss/_infoinvoice.module.scss"

import feature1 from "src/images/info-invoice/feature1.jpg"
import feature2 from "src/images/info-invoice/feature2.jpg"
import feature31 from "src/images/info-invoice/feature3-1.jpg"
import feature32 from "src/images/info-invoice/feature3-2.jpg"
import feature33 from "src/images/info-invoice/feature3-3.jpg"
import layout from "src/images/info-invoice/layout.jpg"

import stepsMobile from "src/images/info-invoice/layout-steps-mobile.png"
import stepsWeb from "src/images/info-invoice/layout-steps-web.png"

// 伝票画像たち

import denpyo1 from "src/images/info-invoice/kaden_recycleL.jpg"
import denpyo2 from "src/images/info-invoice/yamato_hatuL.jpg"
import denpyo3 from "src/images/info-invoice/yamato_hatutimeserL.jpg"
import denpyo4 from "src/images/info-invoice/yamato_chakuL.jpg"
import denpyo5 from "src/images/info-invoice/sagawa_motoL.jpg"
import denpyo6 from "src/images/info-invoice/sagawa_chakuL.jpg"
import denpyo7 from "src/images/info-invoice/jpperikan_hatuL.jpg"
import denpyo8 from "src/images/info-invoice/jpperikan_chakuL.jpg"
import denpyo9 from "src/images/info-invoice/yubin_motoL.jpg"
import denpyo10 from "src/images/info-invoice/yubin_motofukusuL.jpg"
import denpyo11 from "src/images/info-invoice/yubin_chakuL.jpg"
import denpyo12 from "src/images/info-invoice/chainstore_siire2L.jpg"
import denpyo13 from "src/images/info-invoice/chainstore_siiretegakiL.jpg"
import denpyo14 from "src/images/info-invoice/yubin_emsbuppinL.jpg"
import denpyo15 from "src/images/info-invoice/yubin_emssyoruiL.jpg"
import denpyo16 from "src/images/info-invoice/yubin_emsgoodslenL.jpg"
import denpyo17 from "src/images/info-invoice/yubin_pplenL.jpg"
import denpyo18 from "src/images/info-invoice/industrial_waste_manifest_18.jpg"
import denpyo19 from "src/images/info-invoice/industrial_waste_manifest_19.jpg"
import denpyo20 from "src/images/info-invoice/industrial_waste_manifest_20.jpg"
import denpyo21 from "src/images/info-invoice/industrial_waste_manifest_21.jpg"

const options = {
  settings: {
    disablePanzoom: true,
    disableWheelControls: true,
  },
  caption: {
    captionContainerPadding: '0px 0 30px 0',
  },
  buttons: {
    showAutoplayButton: false,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: false,
    showPrevButton: false,
    showThumbnailsButton: false,
  },
  thumbnails: {
    showThumbnails: false,
  },
  progressBar:{},
}

export default function InfoInvoicePage () {
  const location = useLocation();
  const [url, setURL] = useState("");

  useEffect(() => {
    const hostName = location.hostname;
    if (hostName === "www.renk.co.jp") {
      setURL(hostName)
    } else {
      setURL("stg.renk.co.jp")
    }

    let defaultOpen = document.getElementById("defaultOpen")

    if(defaultOpen != null){
      defaultOpen.click();
    }

    let defaultOpen2 = document.getElementById("feature1")

    if(defaultOpen2 != null){
      defaultOpen2.checked = true
    }

    let defaultOpen3 = document.getElementById("updates12")

    if(defaultOpen3 != null){
      defaultOpen3.checked = true
    }
  });

  let click = (id, e) => {
    let i, tabContent, tabLinks;

    tabContent = document.getElementsByClassName("_infoinvoice-module--tabContent--tSOCS");
    for (i = 0; i < tabContent.length; i++) {
      tabContent[i].style.display = "none";
    }

    tabLinks = document.getElementsByClassName("_infoinvoice-module--tabLinks--K6aVQ");
    for (i = 0; i < tabLinks.length; i++) {
      tabLinks[i].className = tabLinks[i].className.replace(" active", "");
      tabLinks[i].style = tabLinks[i].style.backgroundColor = "#fff" ;
      e.currentTarget.style.borderTop = "2px solid #2C577E" ;
      e.currentTarget.style.borderLeft = "2px solid #2C577E" ;
      e.currentTarget.style.borderRight = "2px solid #2C577E" ;
    }
    document.getElementById(id).style.display = "block";
    e.currentTarget.style.backgroundColor = "#EAE9E9" ;
    e.currentTarget.style.borderTop = "2px solid #EAE9E9" ;
    e.currentTarget.style.borderLeft = "2px solid #EAE9E9" ;
    e.currentTarget.style.borderRight = "2px solid #EAE9E9" ;
    e.currentTarget.style.color = "#414141" ;
    e.currentTarget.style.boxShadow = "none" ;
  };

  return (
    <Layout>
      <Seo title="インフォ・ザ・伝票" />
      <div className="pageWrapper">
        <h1 className="productName">インフォ・ザ・伝票</h1>
        <p>
          専用伝票などの記入項目をドットインパクトプリンターで印刷するソフトです。<br />
          運送会社の配送伝票、特殊な納品書、産廃マニフェスト伝票などあらゆる伝票類を簡単に作成することができます。
        </p>

        <hr className="hrGrey" />

        <h2 className="h2Blue">インフォ・ザ・伝票試用版ダウンロード</h2>
        <p className="productMsg">
          30日間期間限定の試用版をご用意しております。こちらからダウンロードしてお試し下さい。<br />
          インストール後30日間は機能の制限無くお使いいただけます。
        </p>

        <div className="btnLarge">
          <a href="/products/infoinvoice-dl/">試用版ダウンロードはこちら
            <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px" ><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
          </a>
        </div>

        <h2 className="h2Blue">価格</h2>
        <p className="productMsg">
          Web直販価格13,750円（税込）
        </p>

        <div className="btnLarge">
          <a href="/online-store/">ライセンス購入はこちら
            <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px" ><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
          </a>
        </div>

        <h2 className="h2Blue">インフォ・ザ・伝票に関するお問い合せ</h2>
        <p className="productMsg">
          お気軽に以下の窓口までお問い合わせください。
        </p>

        <div className="contactWrapper">
          <p className="companyName">リンクチャネル(株)長岡CSセンター</p>
          <p className="telNum">
            <a href="tel:0258-25-6320">お問い合せ専用電話：
              <span>0258-25-6320</span>
            </a>
          </p>
          <p>受付時間：弊社休業日、祭日を除く月曜-金曜の10:00 ~ 17:00</p>
          <p className="telNum">
            <a href="mailto:invoice@renk.co.jp">
              E-Mail：invoice@renk.co.jp
            </a>
          </p>
          <p>Fax：0258-25-6309</p>
        </div>

        <div>
          <div className={styles.tab}>
            <button className={styles.tabLinks} onClick={(e) => click('feature', e)} id="defaultOpen">主な特徴と動作環境</button>
            <button className={styles.tabLinks} onClick={(e) => click('updates', e)}>お知らせ</button>
            <button className={styles.tabLinks} onClick={(e) => click('download', e)} >無料レイアウトダウンロード</button>
            <button className={styles.tabLinks} onClick={(e) => click('layout', e)} >レイアウト作成サービス</button>
          </div>

          <div className={styles.contentWrapper}>
            <div id="feature" className={styles.tabContent}>
              <h2>主な３つの特徴</h2>
              <input type="checkbox" id="feature1" className={styles.checkbox} />
              <label htmlFor="feature1">① ワープロ感覚で専用伝票もかんたん作成</label>
              <div className={styles.checkboxShow}>
                <p>見慣れた伝票を画面に表示</p>
                <p>
                  見たままデータを入力
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><g><rect fill="none" height="24" width="24"/></g><g><g><polygon points="6.41,6 5,7.41 9.58,12 5,16.59 6.41,18 12.41,12"/><polygon points="13,6 11.59,7.41 16.17,12 11.59,16.59 13,18 19,12"/></g></g></svg>
                  プリンターで印刷
                </p>
                <img src={ feature1 } alt="伝票編集画面" className={styles.imgM} />
              </div>

              <input type="checkbox" id="feature2" className={styles.checkbox} />
              <label htmlFor="feature2">② 入力したデータの再利用</label>
              <div className={styles.checkboxShow}>
                <p>伝票作成で入力したデータは保存、読込が可能。さらにEXCEL形式やCSV形式で出力できます。販売管理など他のソフトでご利用下さい。</p>
                <p className="italic">※ 本ソフトからEXCEL形式のファイルを出力するには、Microsoft EXCEL(2003/2002/2000)が別途必要です。</p>
                <img src={ feature2 } alt="伝票データ出力画面" className={styles.imgS} />
              </div>

              <input type="checkbox" id="feature3" className={styles.checkbox} />
              <label htmlFor="feature3">③ 伝票レイアウトもかんたん作成</label>
              <div className={styles.checkboxShow}>
                <p>新たな伝票レイアウトの作成は、イメージスキャナなどで取り込んだ伝票の画像の上に入力、印刷に必要な項目を配置していくだけです。項目間の計算もできます。</p>
                <p>レイアウトを自分で作るのが面倒だ、という方の為にレイアウト作成サービス(有料)もご提供しております。</p>
                <p className="italic">※ 本ソフトには、イメージスキャナから直接画像を読み込む機能はありません。イメージスキャナでの画像読み込みにはイメージスキャナ附属ソフトなど、他のソフトをお使い下さい。</p>
                <img src={ feature31 } alt="伝票レイアウト編集画面" className={styles.imgM} />
                <p>作成したレイアウトは、見やすい選択画面から選んでお使い下さい。</p>
                <img src={ feature32 } alt="伝票選択画面" className={styles.imgS} />
                <p>自社の住所などはあらかじめ登録しておき利用できます。</p>
                <img src={ feature33 } alt="ユーザー情報編集画面" className={styles.imgS} />
              </div>

              <hr className="brdBlue" />

              <h2>動作環境</h2>
              <table className={styles.functionTable}>
                <thead>
                  <tr>
                    <th>対象OS</th>
                    <td>Windows 11、Windows 10、Windows 8/8.1、Windows 7、Windows Vista、Windows XP</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>必須モジュール</th>
                    <td>.NET Framework 3.5 （Windows7、WindowsVistaでご使用の際は不要）</td>
                  </tr>
                  <tr>
                    <th>CPU</th>
                    <td>Intel Pentium ４ 2.40GＨｚ以上を推奨</td>
                  </tr>
                  <tr>
                    <th>メモリ</th>
                    <td>RAM:256MB以上（512MB以上を推奨）</td>
                  </tr>
                  <tr>
                    <th>HDD容量</th>
                    <td>460MB以上の空き容量 *1</td>
                  </tr>
                  <tr>
                    <th>ディスプレイ</th>
                    <td>800×600ドット以上の高解像度・256色以上表示可能なディスプレイ</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>推奨プリンター *2</th>
                    <td>
                      エプソン製ドットインパクトプリンター<br />
                      実行環境（OS）に適したプリンタードライバーがあれば機種は問いません。<br />
                      <p>※プリンターにより使用できる帳票の仕様が異なるため、ご購入の際には印刷サイズや複写枚数をご確認の上、印字テストを行うことをおすすめいたします。</p>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <p className="italic">*1 .NET Framework 3.5 の容量を含みます。</p>
              <p className="italic">*2 対応OSについては各プリンターの詳細をご確認下さい。</p>
            </div>

            <div id="updates" className={styles.tabContent}>
              <input type="checkbox" id="updates12" className={styles.checkbox} />
              <label htmlFor="updates12">インフォ・ザ・伝票価格改定のお知らせ (2023年7月3日)</label>
              <div className={styles.checkboxShow}>
                <p>この度、誠に勝手ながら、弊社の商品「インフォ・ザ・伝票」におきまして、配送料および必要部材の価格高騰を受け、価格改定を行うことになりました。
                <br></br>
                2023年7月3日より下記の通り改定いたします。
                </p>
                <table className={styles.checkboxTable}>
                  <thead>
                    <tr>
                      <th>商品名</th>
                      <th>旧価格</th>
                      <th>新価格</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={styles.checkboxTableRowInfo}>
                      <td>インフォ・ザ・伝票</td>
                      <td>13,400円
                        <br className={styles.taxBr}></br>
                        （税込）
                      </td>
                      <td>13,750円
                        <br className={styles.taxBr}></br>
                        （税込）
                      </td>
                    </tr>
                    <tr className={styles.checkboxTableRowSet}>
                      <td>PLQ-50Sセット</td>
                      <td>141,800円
                        <br className={styles.taxBr}></br>
                        （税込）
                      </td>
                      <td>141,900円
                        <br className={styles.taxBr}></br>
                        （税込）
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>お客様にはご不便をおかけし申し訳ございませんが、ご理解のほど、何卒よろしくお願い申し上げます。</p>
                <div className="btnWrapper">
                  <div className="btnLarge">
                    <a href="/online-store/">ご注文はこちらから
                      <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px" ><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
                    </a>
                  </div>
                </div>
              </div>

              <input type="checkbox" id="updates11" className={styles.checkbox} />
              <label htmlFor="updates11">PLQ-50Sセット販売開始 (2023年3月29日)</label>
              <div className={styles.checkboxShow}>
                <p>用紙位置合わせ不要の「カンタンセット機能」搭載のPLQ-50Sにインフォ・ザ・伝票と各社送り状レイアウト(ヤマト宅急便、ゆうパック、佐川急便)をセットにしてスペシャルプライスでご提供します。</p>
                <p className="price">特別価格 <span>141,800円</span> (税込)</p>
                <p>セット内容</p>
                <ul>
                  <li>PLQ-50S本体</li>
                  <li>インフォ・ザ・伝票 Ver 1.5(USBメモリ)</li>
                  <li>ヤマト宅急便、ゆうパック、佐川急便 送り状レイアウト(USBメモリ)</li>
                  <li>USBケーブル</li>
                </ul>
                <div className="btnWrapper">
                  <div className="btnLarge">
                    <a href="/online-store/">ご注文はこちらから
                      <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px" ><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
                    </a>
                  </div>
                </div>
              </div>

              <input type="checkbox" id="updates10" className={styles.checkbox} />
              <label htmlFor="updates10">Windows11でご使用についてのお知らせ (2022年5月10日)</label>
              <div className={styles.checkboxShow}>
                <li>現在リリース中の「インフォ・ザ・伝票Ver1.5.4」は、Windows11でご使用頂けます。</li>
                <li>インストールには、「.NET Framework 3.5」が必要です。</li>
              </div>

              <input type="checkbox" id="updates9" className={styles.checkbox} />
              <label htmlFor="updates9">PLQ-30Sセット販売開始 (2016年11月30日)</label>
              <div className={styles.checkboxShow}>
                <p>用紙位置合わせ不要の「カンタンセット機能」搭載のPLQ-30Sにインフォ・ザ・伝票と各社送り状レイアウト(ヤマト宅急便、ゆうパック、佐川急便)をセットにしてスペシャルプライスでご提供します。</p>
                <p className="price">特別価格 <span>141,800円</span> (税込)</p>
                <p>セット内容</p>
                <ul>
                  <li>PLQ-30S本体</li>
                  <li>インフォ・ザ・伝票 Ver 1.5(CD-R)</li>
                  <li>ヤマト宅急便、ゆうパック、佐川急便 送り状レイアウト(CD-R)</li>
                  <li>USBケーブル</li>
                </ul>
              </div>

              <input type="checkbox" id="updates8" className={styles.checkbox} />
              <label htmlFor="updates8">Windows10でご使用についてのお知らせ (2015年12月30日)</label>
              <div className={styles.checkboxShow}>
                <ul>
                  <li>現在リリース中の「インフォ・ザ・伝票Ver1.5.4」は、Windows10でご使用頂けます。</li>
                  <li>インストールには、「.NET Framework 3.5」が必要です。</li>
                </ul>
              </div>

              <input type="checkbox" id="updates7" className={styles.checkbox} />
              <label htmlFor="updates7">Ver 1.5.4をリリースしました (2015年2月28日)</label>
              <div className={styles.checkboxShow}>
                <ul>
                  <li>初期設定の消費税率（％）を新税率にしました。</li>
                </ul>
                <p className="italic">※ 従来のユーザーの方は無償で新バージョンをご利用いただけます。Ver1.5.4を試用版ダウンロードからダウンロードしてインストールして下さい。</p>
              </div>

              <input type="checkbox" id="updates6" className={styles.checkbox} />
              <label htmlFor="updates6">Windows8/8.1でご使用についてのお知らせ (2014年5月6日)</label>
              <div className={styles.checkboxShow}>
                <ul>
                  <li>現在リリース中の「インフォ・ザ・伝票Ver1.5.3」は、Windows8/8.1でご使用頂けます。</li>
                  <li>インストールには、「.NET Framework 3.5」が必要です。</li>
                </ul>
              </div>

              <input type="checkbox" id="updates5" className={styles.checkbox} />
              <label htmlFor="updates5">Ver 1.5.3をリリースしました (2012年8月1日)</label>
              <div className={styles.checkboxShow}>
                <ul>
                  <li>伝票データ一覧にソート機能を追加しました。（昇順、降順に並び替えが可能になります）</li>
                  <li>データ読込印刷でCSVデータを使用時、文字コード形式が「UTF-8」の取り扱いも可能になりました。</li>
                </ul>
                <p className="italic">※ 従来のユーザーの方は無償で新バージョンをご利用いただけます。Ver1.5.3を試用版ダウンロードからダウンロードしてインストールして下さい。</p>
              </div>

              <input type="checkbox" id="updates4" className={styles.checkbox} />
              <label htmlFor="updates4">Ver 1.5.2をリリースしました (2011年1月22日)</label>
              <div className={styles.checkboxShow}>
                <ul>
                  <li>Windows7 64bit版でのご使用が可能になりました。</li>
                  <li>伝票レイアウト作成、伝票印刷に関する機能変更はございません。</li>
                </ul>
                <p className="italic">※ 従来のユーザーの方は無償で新バージョンをご利用いただけます。Ver1.5.2を試用版ダウンロードからダウンロードしてインストールして下さい。</p>
              </div>

              <input type="checkbox" id="updates3" className={styles.checkbox} />
              <label htmlFor="updates3">Windows7でご使用についてのお知らせ (2010年5月24日)</label>
              <div className={styles.checkboxShow}>
                <p>「インフォ・ザ・伝票 Ver1.5.1」は、Windows7でご使用頂く際、下記の制限がございますのでご注意ください。</p>
                <ul>
                  <li>ご使用可能OS：Windows7 32bit版</li>
                  <li>
                    データ読込印刷：エクセル2007以降をご使用の場合、ツールの「伝票データ出力」で出力したエクセルデータを、そのまま「データ読込印刷」で読込むことができません。一旦、エクセルで、［Excel97-2003（*.xls）］形式に保存し直してご使用ください。
                  </li>
                </ul>
              </div>

              <input type="checkbox" id="updates2" className={styles.checkbox} />
              <label htmlFor="updates2">インフォ・ザ・伝票LE版のサポート終了のお知らせ (2009年9月1日)</label>
              <div className={styles.checkboxShow}>
                <p>エプソン販売(株)様より無償で提供されていた「インフォ・ザ・伝票LE版」について、誠に勝手ながら2009年9月30日をもってサポートを終了させていただきます。2009年10月1日以降は、LE版についてはE-Mail、電話共お問い合せには対応できませんのでご注意下さい。</p>
                <p>サポートを必要とするお客様は、この機会に製品版の「インフォ・ザ・伝票 Ver1.5」をお買いあげ下さいますようお願いいたします。</p>
              </div>

              <input type="checkbox" id="updates1" className={styles.checkbox} />
              <label htmlFor="updates1">新バージョンVer 1.5をリリースしました (2008年7月15日)</label>
              <div className={styles.checkboxShow}>
                <p>Ver 1.5の新機能</p>
                <ul>
                  <li>任意の検索語で伝票データ検索が可能になりました。</li>
                  <li>計算結果の端数処理方法(四捨五入、切り捨て、切り上げ)を項目ごとに選べるようになりました。</li>
                  <li>伝票データのコピーができるようになりました。既存データの一部の項目を変更して使いたい場合などに便利です。</li>
                  <li>印刷用紙方向をプリンタードライバの指定に従うように変更しました。これにより、用紙幅の狭いプリンターでも幅広の印字が出来るようになりました。</li>
                  <li>マニュフェスト(産業廃棄物管理票)のレイアウトを同梱しました。パソコンにインフォ・ザ・伝票をインストールするだけで、すぐにマニュフェストを作成できます。</li>
                </ul>
                <p className="italic">※ 従来のユーザーの方は無償で新バージョンをご利用いただけます。Ver1.5を試用版ダウンロードからダウンロードしてインストールして下さい。</p>
              </div>
            </div>

            <div id="download" className={styles.tabContent}>
              <p>本製品用のレイアウトをご用意致しました。（各レイアウトの写真をクリックすると拡大して表示されます。）</p>
              <p>以下のレイアウトファイルをダウンロードしてご活用下さい。</p>
              <table className={styles.layoutTbl}>
                <thead>
                  <tr>
                    <th>1.</th>
                    <td>
                      家電リサイクル券<br />
                    （特定家庭用機器廃棄物管理票）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo1 } alt="家電リサイクル券（特定家庭用機器廃棄物管理票）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL1.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL1.zip（153KB）</span>
                      </a>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>2.</th>
                    <td>
                      送り状：ヤマト運輸<br />
                      （宅急便・発払い）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo2 } alt="送り状：ヤマト運輸（宅急便・発払い）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL2.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL2.zip（232KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>3.</th>
                    <td>
                      送り状：ヤマト運輸<br />
                      （宅急便・タイムサービス発払い）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo3 } alt="送り状：ヤマト運輸（宅急便・タイムサービス発払い）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL3.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL3.zip（230KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>4.</th>
                    <td>
                      送り状：ヤマト運輸<br />
                      （宅急便・着払い）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo4 } alt="送り状：ヤマト運輸（宅急便・着払い）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL4.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL4.zip（290KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>5.</th>
                    <td>
                      送り状：佐川急便<br />
                      （飛脚宅配便・元払い）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo5 } alt="送り状：佐川急便（飛脚宅配便・元払い）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL5.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL5.zip（210KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>6.</th>
                    <td>
                      送り状：佐川急便<br />
                      （飛脚宅配便・着払い）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo6 } alt="送り状：佐川急便（飛脚宅配便・着払い）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL6.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL6.zip（196KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>7.</th>
                    <td>
                      送り状：JPエクスプレス<br />
                      （ペリカン便・発払い）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo7 } alt="送り状：JPエクスプレス（ペリカン便・発払い）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL7.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL7.zip（126KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>8.</th>
                    <td>
                      送り状：JPエクスプレス<br />
                      （ペリカン便・着払い）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo8 } alt="送り状：JPエクスプレス（ペリカン便・着払い）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL8.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL8.zip（116KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>9.</th>
                    <td>
                      送り状：日本郵便<br />
                      （ゆうパック・元払い）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo9 } alt="送り状：日本郵便（ゆうパック・元払い）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL9.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL9.zip（333KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>10.</th>
                    <td>
                      送り状：日本郵便<br />
                      （ゆうパック・複数口元払い）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo10 } alt="送り状：日本郵便（ゆうパック・複数口元払い）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL10.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL10.zip（364KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>11.</th>
                    <td>
                      送り状：日本郵便<br />
                      （ゆうパック・着払い）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo11 } alt="送り状：日本郵便（ゆうパック・着払い）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL11.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL11.zip（263KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>12.</th>
                    <td>
                      仕入伝票：チェーンストア統一伝票<br />
                      （ターンアラウンド用２型）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo12 } alt="仕入伝票：チェーンストア統一伝票（ターンアラウンド用２型）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL12.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL12.zip（137KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>13.</th>
                    <td>
                      仕入伝票：チェーンストア統一伝票<br />
                      （手書用）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo13 } alt="仕入伝票：チェーンストア統一伝票（手書用）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL13.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL13.zip（118KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>14.</th>
                    <td>
                      送り状：日本郵便<br />
                      （EMS・物品用・単票紙）<br />
                      <span className="italic">
                        ※ 印刷の向き（横）にてご使用ください
                      </span>
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo14 } alt="送り状：日本郵便（EMS・物品用・単票紙）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL14.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL14.zip（196KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>15.</th>
                    <td>
                      送り状：日本郵便<br />
                      （EMS・書類用・単票紙）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo15 } alt="送り状：日本郵便（EMS・書類用・単票紙）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL15.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL15.zip（143KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>16.</th>
                    <td>
                      送り状：日本郵便<br />
                      （EMS・物品用・連続紙）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo16 } alt="送り状：日本郵便（EMS・物品用・連続紙）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL16.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL16.zip（203KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>17.</th>
                    <td>
                      送り状：日本郵便<br />
                      （POSTAL PARCEL）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo17 } alt="送り状：日本郵便（POSTAL PARCEL）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL17.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL17.zip（164KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>18.</th>
                    <td>
                      マニフェスト積替　単票紙<br />
                      （全国産業資源循環連合会）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo18 } alt="マニフェスト積替　単票紙（全国産業資源循環連合会）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL18.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL18.zip（208KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>19.</th>
                    <td>
                      マニフェスト積替　連続紙<br />
                      （全国産業資源循環連合会）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo19 } alt="マニフェスト積替　連続紙（全国産業資源循環連合会）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL19.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL19.zip（272KB）</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>20.</th>
                    <td>
                      マニフェスト直行　単票紙<br />
                      （全国産業資源循環連合会）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo20 } alt="マニフェスト直行　単票紙（全国産業資源循環連合会）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL20.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL20.zip（206KB）</span>
                      </a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>21.</th>
                    <td>
                      マニフェスト直行　連続紙<br />
                      （全国産業資源循環連合会）
                    </td>
                    <td>
                      <SRLWrapper options={options}>
                        <img src={ denpyo21 } alt="マニフェスト直行　連続紙（全国産業資源循環連合会）" className={styles.imgM} />
                      </SRLWrapper>
                    </td>
                    <td>
                      <a href={`https://` + url + `/download/LAY_DL21.zip`}>
                        ダウンロードする
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                        <br />
                        <span>LAY_DL21.zip（283KB）</span>
                      </a>
                    </td>
                  </tr>
                </tfoot>
              </table>

              <hr className="brdBlue" />
              <h2>本製品へレイアウトの追加登録を行う方法</h2>
              <p>１．伝票レイアウトのファイルを下記フォルダへコピーします。</p>
              <ul>
                <li>コピー先は、
                  <ul>
                    <li>ご使用のPCが WindowsXP の場合は、「ご使用の￥マイドキュメント \ InfoNorth \ InfoInvoice \ layout」です</li>
                    <li>ご使用のPCが Windows11、10、8／8.1、7、VISTA の場合は、「ドキュメント \ InfoNorth \ InfoInvoice \ layout」です。</li>
                  </ul>
                </li>
                <li>コピー先詳細
                  <ul>
                    <li>［ドライブ名（通常はＣドライブ）］：\ユーザー\［ログインしたユーザー名］\ ドキュメント \ InfoNorth \ InfoInvoice \ layout</li>
                  </ul>
                </li>
              </ul>
              <p>２．コピーしたレイアウトファイルを登録します。</p>
              <ul>
                <li>ファイル名をダブルクリックします。登録時、画面上に下記のような、確認画面が表示されます。
                  <ul>
                    <li>【OK】ボタンをクリックし登録終了です。</li>
                  </ul>
                </li>
              </ul>
              <img src={ layout } alt="レイアウトの追加登録画確認画面" className={styles.imgXS} />
            </div>

            <div id="layout" className={styles.tabContent}>
              <h2>伝票レイアウト作成サービスのご案内</h2>
              <p>インフォ・ザ・伝票を御利用で、伝票レイアウトの作成が難しい、時間がないというお客様向けに、伝票レイアウトの作成サービスを御提供しております。</p>
              <p>申込用紙と御利用になりたい伝票をお送り下さい。約2週間で伝票レイアウトをお送りいたします。</p>

              <hr className="brdBlue" />

              <h2>サービスの流れ</h2>
              <img src={ stepsMobile } alt="サービスの流れ" className={styles.stepsMobile} />
              <img src={ stepsWeb } alt="サービスの流れ" className={styles.stepsWeb} />
              <table className={styles.functionTable}>
                <thead>
                  <tr>
                    <th>お送りいただくもの</th>
                    <td>申込書・元伝票(連続帳票の場合は3枚以上、コピー不可)<br />
                      ※詳しくは申込書をご覧下さい。
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>お送り先</th>
                    <td>〒940-0029<br />
                      新潟県長岡市東蔵王2-6-20吉沢ビル2F<br />
                      リンクチャネル(株) 長岡CSセンター<br />
                      Tel 0258-25-6310<br />
                      Fax 0258-25-6309
                    </td>
                  </tr>
                  <tr>
                    <th>料金</th>
                    <td>8,800円(税込)</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>納期</th>
                    <td>お申込み後、約2週間</td>
                  </tr>
                </tfoot>
              </table>

              <div className="btnWrapper">
                <div className="btnLarge">
                  <a href={`https://` + url + `/download/moushikomi.pdf`}>
                    申込書のダウンロード（PDF）はこちら
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                  </a>
                </div>
              </div>

              <hr className="brdBlue" />

              <h2 className="h2Blue">インフォ・ザ・伝票に関するお問い合せ</h2>
              <p className="productMsg">
                お気軽に以下の窓口までお問い合わせください。
              </p>

              <div className="contactWrapper">
                <p className="companyName">リンクチャネル(株)長岡CSセンター</p>
                <p className="telNum">
                  <a href="tel:0258-25-6320">お問い合せ専用電話：
                    <span>0258-25-6320</span>
                  </a>
                </p>
                <p>受付時間：弊社休業日、祭日を除く月曜-金曜の10:00 ~ 17:00</p>
                <p className="telNum">
                  <a href="mailto:invoice@renk.co.jp">
                    E-Mail：invoice@renk.co.jp
                  </a>
                </p>
                <p>Fax：0258-25-6309</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
